:root {
  --blue: #1e90ff;
  --white: #ffffff;
}

* {
  transition: all 0.1s;
  font-family: "Open Sans";
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.loader {
  height: 40vh;
  width: 98%;
  text-align: center;
  margin-top: 35vh;
  position: absolute;
  z-index: 999;
}

.side p {
  text-align: center;
  margin: 0;
  padding: 0;
}

.form-select {
  padding: 3px !important;
}

.side-card {
  border: solid 1px rgba(0, 0, 0, 0.175);
  border-radius: 3px;
  height: 32px;
  background: rgba(1, 84, 49, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes side-card-blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.side-card-blink {
  -webkit-animation: side-card-blink 1s linear infinite;
  -moz-animation: side-card-blink 1s linear infinite;
  -ms-animation: side-card-blink 1s linear infinite;
  -o-animation: side-card-blink 1s linear infinite;
  animation: side-card-blink 1s linear infinite;

  border: solid 1px rgba(0, 0, 0, 0.175);
  border-radius: 5px;
  height: 33px;
  background: #FA6157;
}

.side-card br {
  margin: 0;
  padding: 0;
}

.list-equip {
  overflow-y: scroll;
  height: 75vh
}

.list-equipments {
  color: #dadada;
  border: solid 1px #198754;
  border-radius: 8px;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  margin-right: 8px;
}

.list-equipments:hover {
  background-color: #198754;
}

.card-h6-barchart {
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  margin-top: 0px;
  font-size: 17px;
  text-align: center;
  background: #539a73;
  padding: 5px;
  color: white;
}

.inputdata {
  border-radius: 5px;
  border: solid 1px grey;
  padding: 3px 5px 2px 5px;
  font-size: 13px;
}

.button {
  background: rgba(27, 93, 67, 0.8);
  padding: 2px 15px 1px 15px;
  border-radius: 5px;
  margin-left: 10px;
  color: #F7FAFC;
  border: solid 0px gray;
}

.button:hover {
  background: rgba(21, 67, 48, 0.8);
}

.home,
.services,
.reports,
.contact,
.events,
.support {
  display: flex;
  margin-left: 260px;
  font-size: 2rem;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-h6-barchart {
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  margin-top: 0px;
  font-size: 18px;
  text-align: center;
  background: #539a73;
  padding: 5px;
  color: white;
}


.inputdata {
  border-radius: 5px;
  border: solid 1px grey;
  padding: 3px 5px 2px 5px;
  font-size: 13px;
}

.button {
  background: rgba(27, 93, 67, 0.8);
  padding: 2px 15px 1px 15px;
  border-radius: 5px;
  margin-left: 10px;
  color: #F7FAFC;
  border: solid 0px gray;
}

.button:hover {
  background: rgba(21, 67, 48, 0.8);

}

.content {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.button-financial:hover {
  background: red;
}

.nav-bar {
  text-decoration: none;
  color: silver;
  margin: 6px 6px;
  border-radius: 8px;
  padding: 5px 10px;
}

.nav-bar:hover {
  color: white;
}

#offcanvasNavbar-expand-false {
  background-color: #015431;
}

.navbar-toggler {
  border: solid 1px silver;
}

.name-company {
  color: white;
  margin: 0px 10px;
}

.name-company:hover {
  color: rgba(255, 255, 255, 0.77);
}

#basic-nav-dropdown {
  width: 250px !important;
}

.nav-link-top {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.909);
  padding: 5px 10px;
}

.nav-link-top:hover {
  color: black;
}

.ico-bin {
  cursor: pointer;
  pointer-events: auto !important;
}

.ico-bin:hover {
  pointer-events: auto !important;
  color: black !important;
  transform: scale(1.1)
}

.nav-equip {
  padding-left: 10px;
  padding-right: 10px;
  background: rgb(83, 154, 115);
}


.div-img {
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-size: cover;
  width: 568px;
  height: 794px;
}

.sens-1 {
  border: solid 1px #8FAD15;
  padding: 14px;
  position: absolute;
  margin: 10px 0px 0px 20px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  z-index: 999;
}

.sens-2 {
  position: absolute;
  margin: 120px 0px 0px 280px;
  z-index: 999;
}

.sens-3 {
  position: absolute;
  margin: 185px 0px 0px -115px;
  z-index: 999;
}

.sens-4 {
  position: absolute;
  margin: 340px 0px 0px -115px;
  z-index: 999;
}

.sens-5 {
  position: absolute;
  margin: 525px 0px 0px -115px;
  z-index: 999;
}

.sens-7 {
  border: solid 1px #8FAD15;
  padding: 5px 10px;
  position: absolute;
  margin: 200px 0px 0px 100px;
  border-radius: 5px;
  z-index: 999;
}

.UmidaAmb {
  border: solid 1px #8FAD15;
  padding: 5px 10px;
  position: absolute;
  margin: 35px 0px 0px 357px;
  border-radius: 5px;
  color: grey;
  z-index: 999;
}

.TempAmb {
  border: solid 1px #8FAD15;
  padding: 5px 10px;
  position: absolute;
  margin: -35px 0px 0px 330px;
  border-radius: 5px;
  color: grey;
  z-index: 999;
}

.HSign {
  border: solid 1px #8FAD15;
  padding: 5px 10px;
  position: absolute;
  margin: 305px 0px 0px 472px;
  border-radius: 5px;
  z-index: 999;
}

.DC_Rosca {
  border: solid 1px #8FAD15;
  padding: 5px 10px;
  position: absolute;
  margin: 346px 0px 0px 472px;
  border-radius: 5px;
  z-index: 999;
}

.ImgHSign {
  padding: 5px 10px;
  position: absolute;
  margin: 220px 0px 0px 180px;
  border-radius: 5px;
  z-index: 999;
  width: 200px;
}

.sensores {
  border: solid 1px #8FAD15;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-bottom: 2px;
}

.status {
  color: #fafafc;
  text-align: justify;
  align-self: center;
}

.container {
  position: relative;
}

#image {
  position: absolute;
}

#texto {
  position: absolute;
  font-size: 32px;
  left: 250px;
  top: 135px;
}

body {
  background-color: #dadada;
  color: #212529;
}

body.dark {
  background-color: #212529;
  color: #dadada;
}

@media (prefers-color-scheme: dark) {
  body {
    --color-background: #1f2023;
    --color-foreground: #efefef;
  }
}